import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/ubuntu/flomesh.io-main/src/layouts/Fixed/DocMdx.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Pipy Repo design took its inspirations from Git repo and Git Ops design ideas. It stores documents like configuration, data, scripts (called `}<em parentName="p">{`codebase`}</em>{`) in tree structure format (stored internally as key-value pair) and made accessible as a `}<strong parentName="p">{`Virtual File System`}</strong>{`. `}</p>
    <p>{`Pipy Repo provides a graphical user interface similar to the `}<a parentName="p" {...{
        "href": "/operating/admin-gui/"
      }}>{`Development Console`}</a>{` for easy `}<em parentName="p">{`codebase`}</em>{` development and maintenance. Documentation is built into the graphical user interface along with built-in functions API and types. You can enable GUI, Pipy tutorials during build-time. Refer to `}<a parentName="p" {...{
        "href": "/getting-started/build-install/"
      }}>{`Build Instructions`}</a>{` for more information.`}</p>
    <h3 {...{
      "id": "principle"
    }}>{`Principle`}</h3>
    <p>{`To start a proxy node with `}<em parentName="p">{`codebase`}</em>{` stored in Pipy Repo, you can provide an HTTP URL like `}<inlineCode parentName="p">{`http://repo.flomesh.cn/myrepo/service-x-proxy/`}</inlineCode>{`, this will enable Pipy to `}<strong parentName="p">{`synchronize`}</strong>{` its contents via REST API from `}<em parentName="p">{`codebase`}</em>{` at regular intervals. When specifying a local filesystem path, you can specify a subdirectory. Then the proxy node synchronizes only the contents of the subdirectory specified. Similar to Git Ops, Pipy resources are stored globally, but only those associated with them are retrieved at execution time. `}</p>
    <p>{`When the Proxy node connects to the Pipy Repo `}<em parentName="p">{`codebase`}</em>{`, the Pipy Repo records the status of the node as an `}<strong parentName="p">{`active`}</strong>{`, and Pipy Repo will query the state of active node to find for changes and react appropriately. Similar to concepts of registering a service and sending `}<strong parentName="p">{`heart beat`}</strong>{` signals periodically. Pipy Repo communicates with an HTTP REST API, so your `}<em parentName="p">{`codebase`}</em>{` can be stored on local LAN or WAN.`}</p>
    <p>{`Pipy Repo exposes `}<a parentName="p" {...{
        "href": "/operating/pipy-repo/#rest-api"
      }}>{`REST Endpoint`}</a>{` to provide CRUD operations. In the servicemesh scenario, the adaptor can be used as the control plane and interconnects with various management platforms through REST apis.`}</p>
    <h3 {...{
      "id": "codebase-states"
    }}>{`Codebase States`}</h3>
    <p>{`Below diagram shows the concepts of `}<em parentName="p">{`codebase`}</em>{` states and versioning mechanism. A `}<em parentName="p">{`codebase`}</em>{` that is modified but not `}<strong parentName="p">{`published`}</strong>{` won't be made visible to proxy node.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/docs/repo/codebase-states.png",
        "alt": null
      }}></img></p>
    <h3 {...{
      "id": "codebase-inheritance"
    }}>{`Codebase Inheritance`}</h3>
    <p>{`Code bases can be stand-alone, or they can have an inheritance-like relationship. But in Pipy Repo, they are called `}<strong parentName="p">{`Base`}</strong>{` and `}<strong parentName="p">{`Derivative`}</strong>{`.`}</p>
    <p>{`Based on the base version, you can modify the base version of the script and configuration, or add new script configuration see `}<a parentName="p" {...{
        "href": "/operating/pipy-repo/#usage"
      }}>{`Usage Instructions`}</a></p>
    <p>{`Codebase inheritance enables code reuse at more granular level while still giving you freedom of writing highly cohesive, separting concerns like code and configuration, writing modular scripts.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/docs/repo/codebase-inheritance.png",
        "alt": null
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      